@use '~inter-ui/default' with ($inter-font-display: swap, $inter-font-path: '~inter-ui/Inter (web)');
@use '~inter-ui/variable' with ($inter-font-display: swap, $inter-font-path: '~inter-ui/Inter (web)');
@include default.all;
@include variable.all;

$font-family-sans-serif: 'Inter var', 'Inter', system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

@import 'scss/variables.scss';

@import 'scss/bootstrap.scss';

body {
  color: $color-text;
  font-family: $font-family-sans-serif;
}

.home-div {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $inter-ui-fonts;
  font-weight: 800;
  text-transform: lowercase;
  color: $color-heading;
}

.btn {
  text-transform: lowercase;
  border-width: 0.05em;
  font-weight: 600;
}

.btn-fill {
  text-transform: lowercase;
  color: $white;
  background-color: $color-highlight;
  border-color: $color-highlight;

  &:hover {
    border-color: $color-highlight;
    background-color: $white;
    color: $color-highlight;
  }
}

.btn-outline {
  background-color: $white;
  border-color: $color-highlight;
  color: $color-highlight;
  &:hover {
    color: $white;
    background-color: $color-highlight;
    border-color: $color-highlight;
  }
}
