@import 'scss/variables.scss';

.btn-group {
  margin-bottom: 0.5rem;
  color: $color-heading;
  .btn-vote {
    color: $color-heading;
    font-size: 1.5rem;

    &:hover {
      color: $color-highlight;
    }
  }
  .btn-score {
    font-size: 1rem;
    width: 2rem;
    color: $color-heading;
  }
}
