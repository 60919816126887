@import "~bootstrap/scss/functions";

$inter-ui-fonts: "Inter var", "Inter", "system-ui", "-apple-system", "Segoe UI",
  "Roboto", "Helvetica Neue", "Arial", "Noto Sans", "Liberation Sans",
  sans-serif;

@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

$color-heading: #2c4964;
$color-highlight: $pink;
$color-text: $gray-800;

@mixin highlight-underline {
  content: "";
  position: relative;
  display: block;
  width: 50px;
  height: 3px;
  background: $color-highlight;
  top: 5px;
  // bottom: 0;
  left: 0;
}
