@import 'scss/variables.scss';

.comment-votes {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  height: 2.5em;
  color: $color-heading;
  .btn-vote {
    color: $color-heading;
    font-size: 1.5rem;

    &:hover {
      color: $color-highlight;
    }
  }
  .btn-score {
    font-size: 1rem;
    color: $color-heading;
  }
}

.comment-row {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  border-bottom: 1px solid $gray-300;

  &:last-child {
    border-bottom: none;
  }
}
