@import 'scss/variables.scss';

.social-navbar {
  text-transform: lowercase !important;

  .nav-brand {
    font-weight: 600;
    font-size: 1.5rem;
    color: $color-heading;
    .navbar-logo {
      height: 2rem;
      width: 2rem;
      color: $color-highlight;
      margin-right: 0.5rem;
    }
  }
}
